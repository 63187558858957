.container{
    width: 100%;
    height:100vh;
}

.div{
    margin-left: auto;
    margin-right: auto;
    width: 80%;
    height: 100%;
}

.img{
    width: 95%;
}

.h1{
    font-size: 2.50rem; /* 20px */
    font-weight: 900;
    color:#000;
    text-align: center;
}

.p{
    font-size: 1.00rem;
    font-weight: 500;
    color:gray;
    padding-top: 0% !important;
}
.button{
    padding-left: 24px;
    padding-right: 24px;

    padding-top: 12px;
    padding-bottom: 12px;

    border-radius: 4px;
    font-weight: 600;
    text-decoration: unset;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    border: solid;
    
}

.button:hover{
    cursor: pointer;
}

.btn1{
    background-color: black;
    border-color: rgb(56, 56, 56);
    color: white;
}
.btn1:hover{
    background-color: lightblue;
    border-color: black;
    color: black;
}

.btn2{
    background-color: white;
    border-color: black;
    color:black;
}

.btn2:hover{
    background-color: pink;
    color: white;
}

@media screen and (min-width: 640px){
    
    .container{
        width: 100%;
        height:100vh;
    }
  }

