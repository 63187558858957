.container {
  width: 100vw;

  display: flex;
  flex-direction: column;
  justify-content: center;
}

.containerChild {
  width: 100%;
  overflow-y: scroll;
  height: 90vh;
  padding-top: 5%;
  border-radius: 8px;
}

.header {
  color: black;
  font-size: 2rem;
}
.whatsHappening {
  color: #696969;
  font-size: 2remrem;
  font-weight: bolder;
}
.noTask {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.noTask span {
  color: black;
  font-size: x-large;
}
@media only screen and (min-width: 768px) {
  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .containerChild {
    width: 50%;
  }
}
