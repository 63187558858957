.header {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
}
.headerText {
  margin: 1rem;
  color: black;
  font-size: x-large;
  font-weight: bolder;
}
.headerDesc {
  margin-left: 1rem;
  color: black;
  font-size: large;
}

.container {
  background-color: none;
}

@media screen and (min-width: 640px) {
  .container {
    width: 90vw;

    height: 100vh;
    padding: 1%;
    display: flex;
    flex-direction: column;
    /* align-items: stretch;
    justify-content: stretch; */
    margin: 0 4rem;
    margin-left: 7.5rem;
    overflow-x: scroll;
  }
  .headerText {
    margin: 1rem;
    margin-left: 13%;
  }
  .headerDesc {
    margin-left: 13%;
  }

  .calendar {
    width: 90%;
    margin-bottom: 17rem;
    /* overflow: hidden; */
  }

  .task {
    width: 90%;
    /* overflow: hidden; */
  }
}

@media screen and (min-width: 1000px) {
  .container {
    width: 90vw;
    height: 100vh;
    padding: 1%;
    display: flex;
    flex-direction: row;
    margin-left: 7.5rem;
  }
  .headerText {
    margin: 1rem;
    margin-left: 13%;
  }
  .headerDesc {
    margin-left: 13%;
  }

  .calendar {
    width: 65%;
  }

  .task {
    width: 35%;
  }
}
