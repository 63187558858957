.container {
  padding-block-start: 2rem;
  margin-left: 8rem;
  color: black;
  width: 90vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.imgContainer {
  position: relative;
}

.buttonWrapper {
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.buttonWrapper button {
  all: unset;
  color: white;
  background-color: black;
  border-radius: 9999px;
  padding: 6px;
  padding-bottom: 3px;
  padding-top: 5px;
  border: 2px solid #ccc;
}
.buttonWrapper button:hover {
  cursor: pointer;
  background-color: rgb(55 65 81);
}
.img {
  width: 8rem;
  height: 8rem;
  object-fit: cover;
  border-radius: 9999px;
  border: 4px solid #ccc;
}

.form {
  width: 50%;
  margin-block-end: 2em;
}

.form input {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #ccc;
}
.form textarea {
  border: 2px solid #ccc;
  border-radius: 10px;
  width: 100%;
  max-width: 100%;
  height: 10rem;
}
.form label {
  font-weight: 600;
  font-size: medium;
}

.form p {
  margin-block-start: 0.25em;
  color: rgb(75 85 99);
  font-size: small;
}
.form button {
  padding: 5px;
}

.from button:hover {
  cursor: pointer;
}

.form div {
  margin-block-end: 2em;
}

.link p {
  width: 70px;
}

.link p {
  color: white;
}

.footerBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.footerBtns button {
  height: 50px;
  background-color: rgba(142, 65, 65, 0.564);
  border-color: transparent;
  color: white;
  border-radius: 9px;
  justify-items: center;
  font-size: 1rem;
}

.error input {
  border: 2px solid red;
  color: red;
}
.error label {
  color: red !important;
}
.error p {
  color: red !important;
  font-size: small;
}
.inputfile {
  width: 0;
  height: 0;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.inputfile + label {
  font-size: 1.25em;
  font-weight: 700;
  color: black;
  display: inline-block;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .container {
    width: 100vw;
    margin-left: 0rem;
  }
  .form {
    width: 90%;
  }

  .footerBtns {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
