.container{
    width: 100%;
    border-radius: 16px;
    padding-left: 4%; 
    padding-right:4%;
    padding-top: 5%;
    padding-bottom: 5%;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    display:flex;
    margin-top:10;
    margin-bottom: 5px;
    align-items:center;
    justify-content:space-between;
    color:black
}

.bubble{
    background-color:black;
    color:white;
    border-radius:9999px;
    padding-left:2%;
    padding-right:2%;
    padding-top:1%;
    padding-bottom:1%;
    font-size:12px;
    margin:2px;
    white-space: nowrap;
}


.btn{
    height:100%;
    margin-top:auto;
    margin-bottom:auto;
    all: unset;
    cursor: pointer;
}

.header{
    padding-top:3%;
    padding-bottom:3%;
    font-size:20px;
    font-weight:700
}

.time{
    font-size: 12px;
}