.error input{
    border:2px solid red;
    color: red;
}
.error label{
    color: red !important;
}
.error p{
    color: red !important;
    font-size: small;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.box {
    max-width: 32rem;
    width: 75%;
    padding: 5%;
    box-shadow: 0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1);
    border-radius: 8px;
    border: solid;
    margin-top: 1rem;
}

.form {
    height: 100%;
    width: 100%;
}
.form label{
    padding-bottom: 1.5%;
    font-weight: 550;
    border: none;

}

.form input{
    padding: 0.75rem;
    width: 100%;
    border-radius: 10px;
    border-color: black;
}

.form div{
    margin-top: 2rem;
    border: none;
}

.form p{
    color: rgb(66, 66, 66);
}

.box button {
    padding: 2.5%;
    border-radius: 8px;
    border: 2px solid black;
    width: 100%;
    background-color: rgb(0, 132, 255);
    font-weight: bold;
    color: white;
}


.box button:hover {
    padding: 2.5%;
    border-radius: 8px;
    background-color: rgb(0, 86, 167);
    cursor: pointer;
    font-weight: bold;
}

.form label, input{
    display: block;
    color: black;
}

@media screen and (min-width: 640px) {
    .container {
        height: 100vh;
    }
    .box {
        width: 50%;
        margin: auto;
        padding: 2%;
    }
    
}