.taskItem {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.container {
  width: 90%;
  border-radius: 16px;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 2%;
  padding-bottom: 3%;
  /* box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);*/
  box-shadow: 0 0 7px #938f8f50;
  display: flex;
  margin-top: 10;
  margin-bottom: 3%;
  align-items: center;
  justify-content: space-between;
  color: black;
}

.bubble {
  background-color: black;
  color: white;
  border-radius: 7px;
  padding: 0.2rem;
  font-size: 1rem;
  margin: 0;
  white-space: nowrap;
}

.btn {
  height: 100%;
  margin-top: auto;
  margin-bottom: auto;
  all: unset;
  cursor: pointer;
}

.header {
  padding-top: 3%;
  padding-bottom: 3%;
  font-size: 1.3rem;
  font-weight: 700;
}

.description {
  font-size: 1rem;
  margin-top: 0.1rem;
  margin-bottom: 0.3rem;
}

.time {
  font-size: 0.8rem;
}
