.main {
  /* color: black;
  width: 100%; */
  height: 9%;
  padding: 0;
  background-color: white;
  border-radius: 15px 15px 0 0;
}

.button {
  background-color: white;
  border-radius: 15px 15px 0 0;
  box-shadow: 0 0 50px hsla(0, 43%, 11%, 0.314);
  height: 8vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap: 6px;
  bottom: 0;
  position: fixed;
  padding: 0.3rem;
  margin: 0;
}

.button button {
  background-color: transparent;
  stroke-opacity: 0;

  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.icon {
  background-color: transparent;
  height: 50%;
  width: 50%;
}

/* .circle_plus {
  height: 6em;
  width: 6em;
  font-size: 75% 
}

.circle_plus .circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: solid 0.8em #c78b8c;
}

.circle_plus .circle .horizontal {
  position: absolute;
  background-color: #c78b8c;
  width: 2.4em;
  height: 0.7em;
  top: 1.9em;
  left: 1.05em;
}
.circle_plus .circle .vertical {
  position: absolute;
  background-color: #c78b8c;
  width: 0.7em;
  height: 2.4em;
  top: 1.05em;
  left: 1.9em;
} */

@media screen and (min-width: 768px) {
  .main {
    /* color: black;
    width: 100%; */
    height: 60vh;
    padding: 0;
    background-color: white;
    border-radius: 15px 15px 0 0;
  }
} ;
