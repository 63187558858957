.container {
  width: 100%;
  height: 50vh;
  display: flex;
  /* flex-direction: row; */
  justify-content: center;
  text-align: center;
  margin-bottom: 4rem;
}
.container button:hover {
  cursor: pointer;
}
.containerChild {
  width: 90%;
  /* height: 100%; */
  padding-top: 5%;
  border-radius: 8px;
}
.allUnset {
  all: unset;
}

.colorWhite {
  color: white;
}

.colorWhite:hover {
  color: rgb(203 213 225);
}

.header {
  background-color: black;
  color: white;
  font-weight: 600;
  border-radius: 16px;
  padding: 16px;
  display: flex;
  justify-content: space-between;
}

.gridHeader {
  display: grid;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  padding: 14px;
  font-weight: 600;
  color: gray;
}
.gridHeaderSm {
  display: none;
}

.calendarGrid {
  display: grid;
  /* height: auto; */
  height: 90%;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(7, minmax(0, 1fr));
  padding-bottom: 14px;
}
.calendarDate {
  all: unset;
  cursor: pointer;
}

.desktopHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: rgb(100 116 139);
  border: 1.5px solid rgb(203 213 225);
  padding: 15px;
  border-radius: 10px 10px 0px 0px;
}

.isDefault {
  height: 100%;
}
.isDefault p {
  color: rgb(100 116 139);
  margin-block-start: 0em;
  margin-block-end: 0.25em;
  padding-block-start: 0.25em;
  padding-left: 0.25em;
  scroll-padding-block-start: 1;
}

.isntSameMonth {
  height: 100%;
  background-color: rgb(241 245 249);
}
.isntSameMonth p {
  color: rgb(100 116 139);
  margin-block-start: 0em;
  margin-block-end: 0.25em;
  padding-block-start: 0.25em;
  padding-left: 0.25em;
  scroll-padding-block-start: 1;
}

.isTodaySelected {
  height: 100%;
  background-color: rgba(152, 91, 91, 0.386);
}
.isTodaySelected p {
  color: cornflowerblue;
  font-weight: 600;
  text-decoration: underline;
  margin-block-start: 0em;
  margin-block-end: 0.25em;
  padding-block-start: 0.25em;
  padding-left: 0.25em;
  scroll-padding-block-start: 1;
}
.isTodayUnselected {
  height: 100%;
}
.isTodayUnselected p {
  color: rgb(100 116 139);
  text-decoration: underline;
  font-weight: 600;
  margin-block-start: 0em;
  margin-block-end: 0.25em;
  padding-block-start: 0.25em;
  padding-left: 0.25em;
  scroll-padding-block-start: 1;
}
.isSelected {
  height: 100%;
  background-color: rgba(152, 91, 91, 0.386);
}
.isSelected p {
  color: cornflowerblue;
  margin-block-start: 0em;
  margin-block-end: 0.25em;
  padding-block-start: 0.25em;
  padding-left: 0.25em;
  scroll-padding-block-start: 1;
}

.btns {
  display: flex;
  gap: 6px;
}
.search {
  padding: 10px;
  width: 7em;
  border: 1px solid rgb(100 116 139);
  margin-left: 10%;
  border-radius: 10px;
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
}

.task {
  display: none;
  visibility: hidden;
  padding-left: 5px;
  padding-right: 5px;
  white-space: nowrap;
  margin-bottom: 1%;
}
.task p {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  width: 90%;
  color: white;
  font-size: x-small;
  background-color: rgb(165, 191, 240);
  border-radius: 9999px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.colorWhite {
  color: rgb(148 163 184);
}

/* @media screen and (min-width: 640px) {
  .colorWhite {
    color: rgb(148 163 184);
  }

  .task {
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

  }
  .task p {
    font-size: xx-small;
  }
} */

@media screen and (min-width: 640px) {
  .calendarDate {
    background-color: white;
    display: flex;
    flex-direction: column;
    text-align: left;
    overflow: hidden;
  }
  .calendarGrid {
    background-color: rgb(203 213 225);
    height: 75vh;
    grid-gap: 1px;
    padding: 1.5px;
  }

  .gridHeader {
    display: none;
  }
  .gridHeaderSm {
    display: grid;
    grid-template-columns: repeat(7, minmax(0, 1fr));
    grid-gap: 1px;
    font-weight: 600;
    color: gray;
    padding: 1.5px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: rgb(203 213 225);
  }
  .gridHeaderSm div {
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 5px;
    text-align: left;
    font-size: x-small;
  }
  .isDefault {
    height: 50vh;
  }
  .isSelected {
    height: 50vh;
  }
  .isntSameMonth {
    height: 50vh;
  }
  .isTodaySelected {
    height: 50vh;
  }

  .task {
    visibility: visible;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .task p {
    font-size: xx-small;
  }
}
