.content {
  bottom: 9%;
  position: fixed;
  left: 40%;
}
.content button {
  background-color: transparent;
  stroke-opacity: 0;
  fill: none;
  border: none;
  display: flex;
}
.circle_plus {
  height: 6em;
  width: 6em;
  font-size: 75% /*Will change icon size*/;
  /* fill: white; */
}

.circle_plus .circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: solid 0.8em #c78b8c;
  background-color: rgb(246, 236, 236, 0.7);
  /* background-color: transparent; */
  box-shadow: 0 0 50px hsla(0, 43%, 11%, 0.314);
}

.circle_plus .circle .horizontal {
  position: absolute;
  background-color: #c78b8c;
  width: 2.4em;
  height: 0.7em;
  top: 1.9em;
  left: 1.05em;
}
.circle_plus .circle .vertical {
  position: absolute;
  background-color: #c78b8c;
  width: 0.7em;
  height: 2.4em;
  top: 1.05em;
  left: 1.9em;
}

@media screen and (min-width: 768px) {
  .content {
    bottom: 11%;
    position: fixed;
    left: 50%;
  }
  .circle_plus {
    font-size: 100% /*Will change icon size*/;
    /* fill: white; */
  }
}
