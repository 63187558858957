.goal {
  display: grid;
  grid-template-columns: 1.5fr 4fr 1fr;
  grid-template-rows: 1fr 1fr 0.5fr 1fr;
  justify-content: center;
  align-items: center;
  color: rgb(45, 37, 37);
  font-weight: semi-bold;
}
.noDescription {
  display: grid;
  grid-template-columns: 1.5fr 4fr 1fr;
  grid-template-rows: 1fr 1fr;
  justify-content: center;
  align-items: center;
  color: rgb(45, 37, 37);
  font-weight: semi-bold;
}
.timeNoDescription {
  display: flex;
  flex-direction: row;
  justify-content: start;
  grid-row: 2;
  grid-column: 2;
}

.modal {
  line-height: 20px;
  position: fixed;
  bottom: 0;
  background-color: #c3c7be;
  border-radius: 40px;
  text-align: center;
  z-index: 11;
}
.themeColorBtn {
  height: 70%;
  grid-column: 1;
  grid-row: 1;
  border: none;
  border-radius: 5px;
  padding: 0;
  margin: 0;
}

.headerText {
  font-size: 1.5rem;
  margin-left: 0.5rem;
  padding: 0.5rem;
  grid-column: 2;
  grid-row: 1;
}
.moreBtn {
  align-self: top;
  grid-column: 3;
  grid-row: 1;
}

.date {
  display: flex;
  flex-direction: row;
  justify-content: start;
  grid-row: 3;
  grid-column: 2;
}

.description {
  align-self: top;
  justify-self: left;
  margin-left: 1.2rem;
  grid-row: 2;
  grid-column: 2 / span 3;
}

.timeClock {
  align-self: top;
  justify-self: left;
  margin-left: 1.2rem;
  margin-right: 0.5rem;
  grid-row: 3;
  grid-column: 2;
}

.itemButton {
  height: 40px;
  grid-row: 4;
  grid-column: 1 / span 3;
  margin-top: 1.5rem;

  border-radius: 9px;
  cursor: pointer;
  border: solid 0.2em #c78b8c;
  background-color: whitesmoke;
  box-shadow: 0 0 10px #938f8f50;
}

.items {
  width: 90%;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .card {
    margin: 2rem;
    width: 35vw;
  }
}
