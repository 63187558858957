.task,
.overdueTask {
  height: 5%;
  background-color: white;
  box-shadow: 0 0 10px #645f5f50;
  color: black;

  /* animation properties*/
  animation-name: my-animation;
  animation-duration: 1.5s;
  animation-direction: alternate;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  border-radius: 10px;
  /* position: absolute; */
  left: 0;
  right: 0;
  margin-left: 9%;
  margin-right: 10%;
  margin-top: 1rem;
  margin-bottom: 0.2rem;
  padding: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.task {
  border-color: #efdada;
}
.overdueTask {
  border-color: rgb(139, 31, 31);
  border: 1px solid rgb(139, 31, 31);
  box-shadow: 0 0 9px #991e1e50;
}

@keyframes my-animation {
  from {
    width: 50%;
  }
  to {
    width: 80%;
  }
}

.time {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.active {
  color: rgb(43, 108, 43);
  font-size: 1rem;
  margin-left: 0.3rem;
}
.goalTime {
  color: rgb(34, 62, 138);
  font-size: 0.9rem;
  margin-left: 0.3rem;
}

.overdue {
  font-size: 0.9rem;
  margin-left: 0.3rem;
  color: rgb(139, 31, 31);
}

.taskBtn {
  border: none;
  width: 100%;
  background-color: transparent;
}
