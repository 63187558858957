.colors {
  width: 100%;
  /* margin: 0.6rem 0.4rem 0.3rem 0.4rem; */
  display: flex;
  flex-direction: column;
}
.colorText {
  align-self: start;
  margin-bottom: 0.3rem;
}

.colorBtns {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 0.4rem 0.2rem;
}

.colorBtns button {
  align-self: center;
  border-radius: 50%;
  margin: 0.3rem;
  width: 40px;
  height: 40px;
  /* border: none; */
  box-shadow: 0 0 10px #4a3c3c50;
}

.color1 {
  background-color: #a6cba8;
  border: none;
}
.color2 {
  background-color: #75c0c6;
  border: none;
}
.color3 {
  background-color: #b4737c;
  border: none;
}
.color4 {
  background-color: #686dad;
  border: none;
}
.color5 {
  background-color: #9868ac;
  border: none;
}

.Rainbow_Circle {
  border: none;
  --Circle_Diameter: 50%;
  top: calc(50% - var(--Circle_Diameter) / 2);
  left: calc(50% - var(--Circle_Diameter) / 2);
  height: var(--Circle_Diameter);
  width: var(--Circle_Diameter);
  border-radius: calc(var(--Circle_Diameter) / 2);
  background: linear-gradient(
    139.84deg,
    #a692ed 14.35%,
    #6cecad 45.6%,
    #d87eaa 82.79%
  );
  animation: Rotate 3s linear infinite;
}
@keyframes Rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active {
  border: solid 2px #171718;
}

.colorPicker {
  background-color: transparent;
  border: none;
  opacity: 0;
  margin: 0;
  padding: 0;
  border: 0;
}

.colorPicker::-webkit-color-swatch-wrapper {
  padding: 0;
  margin: 0;
  background: none;
}
.colorPicker::-webkit-color-swatch {
  border: none;
}

@media only screen and (min-width: 768px) {
  .colors {
    width: 100%;
    /* margin: 0.6rem 0.4rem; */
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .colors label {
    width: 90%;
    margin: 0.6rem 0 0.6rem 6rem;
  }
  .colorText {
    /* align-self: center; */
  }
  .colorBtns {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 0.4rem 7rem;
  }
}
