h1 {
  color: rgb(63, 58, 58);
  font-family: "Lato", sans-serif;
}

.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.main {
  height: 100vh;
  border-radius: 8px;
  margin-bottom: 15vh;
  overflow-y: scroll;
}

.modal {
  line-height: 20px;
  position: fixed;
  bottom: 0;
  background-color: #c3c7be;
  border-radius: 40px;
  text-align: center;
  z-index: 11;
}

.noGoals {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.noGoals span {
  margin: 2rem 2rem 1rem 2rem;
  color: black;
  font-size: x-large;
  text-align: center;
}
.noGoals .motivation {
  font-size: large;
  text-align: center;
}

@media only screen and (min-width: 768px) {
  .main {
    display: flex;
    overflow: auto;
    /* flex-direction: row;
    justify-content: start;
    align-items: start;
    flex-wrap: nowrap; */

    padding: 1rem 1rem;
    /* width: 80vw; */
    width: 70vw;
    height: 80vh;
    margin-bottom: 9vh;
    margin-left: 11%;
    /* overflow-y: scroll;
    overflow-x: scroll; */
    scroll-snap-type: x mandatory;
  }

  .main .goal {
    flex-shrink: 0;
    scroll-snap-align: start;
  }
}
