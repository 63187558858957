/* header {
  padding: 0.7rem;
} */

.header {
  padding: 0.7rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.control_group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: black;
}
.form {
  margin: 1.5rem;
  width: 80vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}
.form label {
  align-self: start;
  padding: 0 1rem;
  font-weight: 600;
}

.textInput input,
select {
  margin: 1rem 0;
  padding: 1rem;
  width: 100%;
  height: 45px;
  font-size: 1rem;
  border-radius: 9px;
  border: none;
  background-color: rgb(231, 228, 228, 0.5);
}
.textInput select {
  margin: 1rem 0;
  height: 50px;
  font-size: 1rem;
}

.container header {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.5rem 3rem;
  color: black;
}
.container {
  background-color: white;
  border-radius: 20px 20px 0 0;
  position: absolute;
  width: 100%;
  padding: 0;
  clear: both;
  bottom: 0;
}

.dateTime {
  display: grid;
  grid-template-columns: 2fr 0.3fr 2fr;
  grid-template-rows: 0.5fr 3fr;
  justify-items: start;
}
.dateLabel {
  grid-row: 1;
  grid-column: 1;
}
.dateInput {
  grid-row: 2;
  grid-column: 1;
}
.timeLabel {
  grid-row: 1;
  grid-column: 3;
}
.timeInput {
  grid-row: 2;
  grid-column: 3;
}

.duration {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: stretch;
}
.durationLabel {
  margin-bottom: 1rem;
}

.durationButtons {
  background-color: rgb(231, 228, 228, 0.5);
  /* margin: 1rem 0; */
  width: 100%;
  height: 50px;

  border-radius: 9px;
  border: none;
  /* padding: 1rem; */
  display: flex;
  flex-direction: row;
  /* align-items: flex-start; */
  justify-content: space-evenly;
}
.durationButtons button {
  border: none;
  font-size: 1rem;
  border-radius: 9px;
}

.active {
  background-color: rgb(217, 174, 174);
}

.completed {
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  align-content: center;
}

.completed input {
  margin: 0;
  width: 20px;
  height: 20px;

  border: none;

  background-color: rgb(231, 228, 228, 0.5);
}

.slidecontainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.slidecontainer input {
  width: 85%;
}
.slidecontainer input,
select {
  margin: 0 1rem 1rem 1rem;
  padding: 0;
}

.progress_bar {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  /* background-color: #971313;
  color: red; */
  position: relative;
  stroke-width: 10px;
}
.progress_bar_fill {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #caa2a264;
  color: red;
  /* transform: rotate(90deg); */
  transform-origin: center;
  position: absolute;
  top: 0;
  left: 0;
  clip-path: circle(50%);
}

@media only screen and (min-width: 768px) {
  .form label {
    align-self: start;
    padding: 0 3rem;
  }

  .container header {
    padding: 1rem 4rem;
    margin: 1 5rem;
    font-size: 2rem;
  }

  .header {
    margin-right: 17rem;
  }
  .header label {
    font-weight: 7rem;
  }
  .form {
    margin: 1.5rem;
    width: 80vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
  .form label {
    align-self: start;
    padding: 0 7rem;
  }
  .textInput input,
  .textInput select,
  .durationButtons {
    margin-top: 0.5rem;
    margin-left: 6rem;

    width: 70%;
    height: 45px;
    font-size: 1rem;
    border-radius: 9px;
  }
  .dateTime {
    width: 80%;
    grid-template-columns: 7fr 1fr 7fr;
    grid-template-rows: 1fr 3fr;
  }

  .completed {
    width: 80vw;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: start;
    align-content: start;
  }
  .completed label {
    align-self: start;
    padding: 0 2rem 0 7rem;
    margin: 0;
  }
  .completed input {
    margin: 0;
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
  }

  .slidecontainer {
    width: 80vw;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
  }
  .slidecontainer input {
    width: 63%;
  }
  .slidecontainer input {
    margin-top: 1rem;
    margin-right: 2rem;
  }
  .slidecontainer span {
    font-size: 1.2rem;
  }
}
