.header {
  padding: 1rem 2.5rem 0.5rem 2.5rem;
  display: flex;
  flex-direction: column;
}
.headerTitle {
  color: black;
  font-size: x-large;
  font-weight: 500;
  margin-bottom: 0.7rem;
}
.headerDesc {
  color: black;
  font-size: medium;
}

.content {
  margin: 1rem;
  padding-top: 1rem;
  width: 90vw;
}

body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  color: #eeeeee;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 90%;
  margin: 1rem;
  padding: 0 1rem;
  gap: 7px;
  --b: 3px; /* the border thickness */
  --h: 1.6em; /* the height */
}

.buttons button {
  --_c: #c78b8c;
  flex: calc(1.25 + var(--_s, 0));
  min-width: 0;
  font-size: 30px;
  font-weight: bold;
  height: var(--h);
  cursor: pointer;
  color: var(--_c);
  border: var(--b) solid var(--_c);
  background: conic-gradient(
      at calc(100% - 1.3 * var(--b)) 0,
      var(--_c) 209deg,
      #0000 211deg
    )
    border-box;
  clip-path: polygon(0 0, 100% 0, calc(100% - 0.577 * var(--h)) 100%, 0 100%);
  padding: 0 calc(0.288 * var(--h)) 0 0;
  margin: 0 calc(-0.288 * var(--h)) 0 0;
  box-sizing: border-box;
  transition: flex 0.4s;
  border-radius: 8px;
}
.buttons button + button {
  --_c: #8d4646;
  flex: calc(0.75 + var(--_s, 0));
  background: conic-gradient(
      from -90deg at calc(1.3 * var(--b)) 100%,
      var(--_c) 119deg,
      #0000 121deg
    )
    border-box;
  clip-path: polygon(calc(0.577 * var(--h)) 0, 100% 0, 100% 100%, 0 100%);
  margin: 0 0 0 calc(-0.288 * var(--h));
  padding: 0 0 0 calc(0.288 * var(--h));
}

.buttons button:focus-visible {
  outline-offset: calc(-2 * var(--b));
  outline: calc(var(--b) / 2) solid #000;
  background: none;

  clip-path: none;
  margin: 0;
  padding: 0;
}
.buttons button + button:focus-visible {
  outline-offset: calc(-2 * var(--b));
  outline: calc(var(--b) / 2) solid #000;
  background: none;

  clip-path: none;
  margin: 0;
  padding: 0;
}

.buttons button:focus-visible + button {
  background: none;
  clip-path: none;
  margin: 0;
  padding: 0;
}
.buttons button:has(+ button:focus-visible) {
  background: none;

  clip-path: none;
  margin: 0;
  padding: 0;
}
button:hover,
button:active:not(:focus-visible) {
  --_s: 0.75;
}
button:active {
  box-shadow: inset 0 0 0 100vmax var(--_c);
  color: #fff;
}

.buttons .goalsActive {
  flex: calc(1.25 + var(--_s, 0));
  background: linear-gradient(to right, #b57171 0%, #f3cdb5 51%, #d08c8c 100%);
  color: white;
}
.buttons button {
  flex: calc(0.75 + var(--_s, 0));
}
.buttons .tasksActive {
  flex: calc(1.25 + var(--_s, 0));
  background: linear-gradient(to right, #985454 0%, #f3cdb5 51%, #d08c8c 100%);
  color: white;
}

@media screen and (min-width: 768px) {
  .header {
    padding: 2rem 4rem 2rem 7rem;
    margin-left: 4%;
  }

  .buttons {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 1rem;
    margin-left: 5%;
    padding: 0 5rem;
    gap: 7px;
    --b: 3px; /* the border thickness */
    --h: 2em; /* the height */
  }
} ;
