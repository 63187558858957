:root {
  --primary-color: #d96aa7;
  --secondary-color: #ba8a8a;
  --complimentary-color: #88bfb5;
  --contrast-color: #f2e527;
  --light-color: #d2a9d9;
}

.container {
  background: #f8f7f7;
  min-height: 100vh;
  font-family: Montserrat, sans-serif;
}

.subcontainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: fit-content;
}
.subcontainer + .subcontainer {
  margin-top: 40px;
  height: 35%;
  justify-content: space-evenly;
}
.last {
  border-top: 1px solid darkgray;
}
.links h1 {
  color: #fff;
  font-size: 50px;
  text-transform: uppercase;
  opacity: 1;
}

.navigation {
  position: fixed;
  left: 0;
  /* z-index: 50; */
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
  width: 7.5em;
  background: #f5f5f5;
  padding-top: 0.75em;
  padding: 10px;
}

.link {
  width: 100%;
  text-decoration: none;
  background-color: transparent;
  stroke-opacity: 0;
  border: none;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  color: rgb(31 41 55);
  font-size: small;
}

.link:hover {
  color: #79a7fd;
  cursor: pointer;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
}

.plus {
  width: 100%;
  border-radius: 5px;
  padding: 8px;
  background-image: linear-gradient(to right, #8360c3, #79a7fd) !important;
  color: white !important;
  font-weight: 600;
  justify-content: center;
}

.plus:hover {
  background-image: linear-gradient(to right, #8360c381, #79a7fd5b) !important;
  cursor: pointer;
}

.circle_plus {
  height: 6em;
  width: 6em;
  font-size: 100% /*Will change icon size*/;
}

.circle_plus .circle {
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: solid 0.8em #f0e8e8;
}

.circle_plus .circle .horizontal {
  position: absolute;
  background-color: #e5dcdc;
  width: 2.4em;
  height: 0.7em;
  top: 1.9em;
  left: 1.05em;
}
.circle_plus .circle .vertical {
  position: absolute;
  background-color: #ece0e1;
  width: 0.7em;
  height: 2.4em;
  top: 1.05em;
  left: 1.9em;
}
