.submit {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.5rem 2rem 0.5rem;
}
.submitButton {
  width: 70%;
  height: 50px;
  background-color: rgb(44, 42, 42);
  color: white;
  border-radius: 9px;
  font-size: 1rem;
}
@media only screen and (min-width: 768px) {
  .submit {
    margin: 1rem 12rem 1rem 4rem;
  }
  .submitButton {
    width: 57vw;
  }
}
